<template>
    <cluji-card title="Cœurs">
        <love-message-form/>
    </cluji-card>
</template>

<script>
    const ClujiCard       = () => import('@/components/ClujiCard');
    const LoveMessageForm = () => import('@/components/LoveMessageForm');
    export default {
        name: 'AdminToyBox',
        components: {LoveMessageForm, ClujiCard}
    };
</script>
